import { isUserLoggedIn } from "@/auth/utils";

const initialAbility = [
  {
    action: "read",
    subject: "Auth",
  },
];

if (isUserLoggedIn()) {
  initialAbility.push({
    action: "read",
    subject: "Investments",
  });
}

export { initialAbility };

export const _ = undefined;
