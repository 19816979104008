import PortfolioRepository from "./PortfolioRepository";
import TransactionRepository from "./TransactionRepository";

const repositories = {
  portfolios: PortfolioRepository,
  transactions: TransactionRepository,
};

export default {
  get: (name) => repositories[name],
};
