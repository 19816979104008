/* eslint-disable no-param-reassign */
import * as investmentsApi from "@/api/investments";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const PortfolioRepository = RepositoryFactory.get("portfolios");
const TransactionRepository = RepositoryFactory.get("transactions");

export default {
  namespaced: true,
  state: {
    currencies: [
      // TODO: load data from api
      {
        value: "RUB",
        name: "Рубли",
        symbol: "₽",
        multiplicity: 1,
      },
      {
        value: "USD",
        name: "Доллары",
        symbol: "$",
        multiplicity: 1,
      },
      {
        value: "EUR",
        name: "Евро",
        symbol: "€",
        multiplicity: 1,
      },
      {
        value: "HKD",
        name: "Гонконгские доллары",
        symbol: "H$",
        multiplicity: 1,
      },
    ],
    secGroups: [
      { value: "money", name: "Деньги" },
      { value: "shares", name: "Акции" },
      { value: "bonds", name: "Облигации" },
      { value: "etfs", name: "ETF" },
    ],
    portfolios: {},
    curPortfolio: JSON.parse(localStorage.getItem("cur-portfolio")) || null,
    curCurrency: "RUB",
    curTransaction: null,
    // Form
    updatePortfolioData: false,
    // Asset filters
    soldFilters: {
      shares: JSON.parse(localStorage.getItem("soldFilters-shares")) || false,
      bonds: JSON.parse(localStorage.getItem("soldFilters-bonds")) || false,
      etfs: JSON.parse(localStorage.getItem("soldFilters-etfs")) || false,
    },
    selectSecurityDetail: null,
  },
  getters: {
    securityGroupList: ({ secGroups }) => secGroups,
    currencyList: ({ currencies }) => currencies,
    portfoliosList: ({ portfolios }) => Object.values(portfolios),
    curPortfolio: ({ portfolios, curPortfolio }) => portfolios[curPortfolio] || { name: "" },
    curCurrency: ({ currencies, curCurrency }) =>
      currencies.find((cur) => cur.value === curCurrency),
    curTransaction: ({ curTransaction }) => curTransaction,
    updatePortfolioData: ({ updatePortfolioData }) => updatePortfolioData,
    curSecurityDetail: ({ selectSecurityDetail }) => selectSecurityDetail,
  },
  mutations: {
    RESET(state) {
      state.portfolios = {};
      state.curPortfolio = null;
      state.curTransaction = null;
    },
    SET_SELECT_SECURITY_DETAIL(state, payload) {
      state.selectSecurityDetail = payload;
    },
    SET_PORTFOLIO(state, val) {
      state.curPortfolio = val;

      localStorage.setItem("cur-portfolio", JSON.stringify(val));
    },
    SET_PORTFOLIOS(state, payload) {
      state.portfolios = payload;
    },
    SET_TRANSACTION(state, val) {
      state.curTransaction = val;
    },
    SET_UPDATE_PORTFOLIO_DATA(state) {
      state.updatePortfolioData = !state.updatePortfolioData;
    },
    SET_SOLD_FILTER_SHARES(state, val) {
      state.soldFilters.shares = val;

      localStorage.setItem("soldFilters-shares", JSON.stringify(val));
    },
    SET_SOLD_FILTER_BONDS(state, val) {
      state.soldFilters.bonds = val;

      localStorage.setItem("soldFilters-bonds", JSON.stringify(val));
    },
    SET_SOLD_FILTER_ETFS(state, val) {
      state.soldFilters.etfs = val;

      localStorage.setItem("soldFilters-etfs", JSON.stringify(val));
    },
  },
  actions: {
    changeCurrency({ state }) {
      state.curCurrency = state.curCurrency === "rub" ? "usd" : "rub";
    },
    async initalLoad({ dispatch }) {
      //
    },

    // Portfolios
    async fetchPortfolios({ commit }) {
      try {
        const response = await PortfolioRepository.get();
        const portfolios = response.reduce((acc, rawData) => {
          acc[rawData.id] = rawData;
          return acc;
        }, {});

        commit("SET_PORTFOLIOS", portfolios);
        return portfolios;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async getPortfolioDetail(context, { id }) {
      try {
        const response = await PortfolioRepository.getPortfolio(id);
        return response;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async createPortfolio({ dispatch }, { data }) {
      try {
        await PortfolioRepository.create(data);
        await dispatch("fetchPortfolios");
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async updatePortfolio({ dispatch }, { data }) {
      try {
        await PortfolioRepository.update(data);
        await dispatch("fetchPortfolios");
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async deletePortfolio({ dispatch }, { id }) {
      try {
        await PortfolioRepository.delete(id);
        await dispatch("fetchPortfolios");
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    // Transaction
    async getTransactions(context, params) {
      try {
        const response = await TransactionRepository.get(params);
        return response;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async createTransaction(context, data) {
      try {
        await TransactionRepository.create(data);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async getTransactionDetail(context, id) {
      try {
        const response = await TransactionRepository.getTransaction(id);
        return response;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async updateTransaction(context, data) {
      try {
        await TransactionRepository.update(data);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async deleteTransaction(context, id) {
      try {
        await TransactionRepository.delete(id);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    //
    async loadSecurityData(context, { data }) {
      try {
        const response = await investmentsApi.loadSecurityData(data);
        return response;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async addSecurity(context, { securityGroup, data }) {
      try {
        const response = await investmentsApi.securityAdd(securityGroup, data);
        return response;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
  },
};
