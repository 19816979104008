import router from "@/router";
import { getUser } from "@/api/user";
import { login } from "@/api/auth";

export default {
  namespaced: true,
  state: {
    user: {
      email: undefined,
      fullName: undefined,
    },
  },
  getters: {},
  mutations: {
    SET_USER(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.user = payload;
    },
  },
  actions: {
    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    async loadUser({ commit, dispatch }) {
      const { data: userData } = await getUser();
      commit("SET_USER", userData);

      dispatch("ability/setRules", userData.isSuperuser, { root: true });
    },
    async login({ commit, dispatch }, formData) {
      await login(formData);

      await dispatch("loadUser");
      const { data: userData } = await getUser();
      commit("SET_USER", userData);
      localStorage.setItem("userId", userData.id);

      dispatch("ability/setRules", userData.isSuperuser, { root: true });
    },
    logout({ commit, dispatch }) {
      // Remove userData from localStorage
      localStorage.removeItem("userId");
      commit("SET_USER", {
        email: undefined,
        fullName: undefined,
      });

      // Remove user settings
      localStorage.removeItem("cur-portfolio");
      localStorage.removeItem("soldFilters-shares");
      localStorage.removeItem("soldFilters-bonds");
      localStorage.removeItem("soldFilters-etfs");

      // Reset investments data
      commit("investments/RESET", null, { root: true });

      // Reset ability
      dispatch("ability/resetRules", undefined, { root: true });

      // Redirect to login page
      router.push({ name: "auth-login" });
    },
  },
};
