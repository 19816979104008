export default [
  {
    path: '/investments',
    component: () => import('@/layouts/investments/LayoutInvestments.vue'),
    meta: {
      resource: 'Investments',
    },
    children: [
      {
        path: '',
        component: () => import('@/layouts/investments/LayoutPortfolioData.vue'),
        meta: {
          resource: 'Investments',
        },
        children: [
          {
            path: '/overview',
            name: 'inv-overview',
            component: () => import('@/views/investments/Overview.vue'),
            meta: {
              resource: 'Investments',
            },
          },
          {
            path: '/analytics',
            name: 'inv-analytics',
            component: () => import('@/views/investments/Analytics.vue'),
            meta: {
              resource: 'Investments',
            },
          },
          {
            path: '/transactions',
            name: 'inv-transactions',
            component: () => import('@/views/investments/Transactions.vue'),
            meta: {
              resource: 'Investments',
            },
          },
          {
            path: '/rebalancing',
            name: 'inv-rebalancing',
            component: () => import('@/views/investments/Rebalancing.vue'),
            meta: {
              resource: 'Investments',
            },
          },
        ],
      },
      {
        path: '/portfolios',
        name: 'inv-portfolios',
        component: () => import('@/views/investments/Portfolios.vue'),
        meta: {
          resource: 'Investments',
        },
      },
      {
        path: '/securities',
        name: 'inv-securities',
        component: () => import('@/views/investments/Securities.vue'),
        meta: {
          resource: 'Investments',
        },
      },
      {
        path: '/securities/:code',
        name: 'inv-security',
        component: () => import('@/views/investments/Security.vue'),
        meta: {
          navActiveLink: 'inv-securities',
          resource: 'Investments',
        },
      },
      {
        path: '/bond-yeld',
        name: 'inv-bond-yeld',
        component: () => import('@/views/investments/BondYeld.vue'),
        meta: {
          resource: 'Investments',
        },
      },
      // {
      //   path: '/fin-statement',
      //   name: 'inv-fin-statement',
      //   component: () => import('@/views/investments/FinancialStatement.vue'),
      //   meta: {
      //     resource: 'Investments',
      //   },
      // },
      // {
      //   path: '/fin-statement/:slug',
      //   name: 'inv-fin-statement-company',
      //   component: () => import('@/views/investments/FinStatementInfo.vue'),
      //   meta: {
      //     navActiveLink: 'inv-fin-statement',
      //     resource: 'Investments',
      //   },
      // },
    ],
  },
];
