import { initialAbility } from "@/libs/acl/config";

export default {
  namespaced: true,
  state: {
    rules: null,
  },
  getters: {
    getRules: (state) => state.rules,
  },
  mutations: {
    SET_RULES(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.rules = payload;
    },
  },
  actions: {
    resetRules({ commit }) {
      commit("SET_RULES", initialAbility);
    },
    setRules({ commit }, isSuperuser) {
      const rules = [];
      if (isSuperuser) {
        rules.push({ action: "manage", subject: "all" });
      } else {
        rules.push({ action: "read", subject: "Auth" });
        rules.push({ action: "read", subject: "Investments" });
      }
      commit("SET_RULES", rules);
    },
  },
};
