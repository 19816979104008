export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },
  // {
  //   path: '/forgot-password',
  //   name: 'auth-forgot-password',
  //   component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/pages/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      action: 'read',
      resource: 'Auth',
    },
  },
  // {
  //   path: '/pages/miscellaneous/under-maintenance',
  //   name: 'misc-under-maintenance',
  //   component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/pages/account-setting',
  //   name: 'pages-account-setting',
  //   component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
  //   meta: {
  //     pageTitle: 'Account Settings',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Account Settings',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/pages/profile',
  //   name: 'pages-profile',
  //   component: () => import('@/views/pages/profile/Profile.vue'),
  //   meta: {
  //     pageTitle: 'Profile',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Profile',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
];
