import axiosIns from "@/libs/axios";

const url = "api/investments";

export async function securityInPortfolio({ portfolioId, securityBoardId, date }) {
  const params = {
    portfolio_id: portfolioId,
    security_board_id: securityBoardId,
    date,
  };

  const { data } = await axiosIns.get("securities/in-portfolio", { params });
  return data;
}

export async function getPrice({ securityBoardId, date }) {
  const params = {
    security_board_id: securityBoardId,
    date,
  };

  const { data } = await axiosIns.get("securities/get-price", { params });
  return data;
}

export async function getPortfolioOwerview(pid, currencyCode) {
  const body = {
    portfolios: [pid],
    currency: currencyCode,
  };

  const { data } = await axiosIns.post("analytics/", body);
  return data;
}

// Security
export async function secSearch({ secGroup, query }) {
  const params = {
    sec_group: secGroup,
    q: query,
  };

  const { data } = await axiosIns.get("securities/search", { params });
  return data;
}

export async function loadSecurityData({ code }) {
  const params = {
    code,
  };

  const { data } = await axiosIns.get("/securities/load-data", { params });
  return data;
}

export async function securityAdd(securityGroup, data) {
  const { data: result } = await axiosIns.post(`/securities/${securityGroup}/`, data);
  return result;
}

export async function securities(params) {
  const { data } = await axiosIns.get("/securities/", {
    params,
  });
  return data;
}

export async function bondYeld(params) {
  const { data } = await axiosIns.get("/securities/bond-yeld", {
    params,
  });
  return data;
}

// Utils
export async function updateDailyPrices() {
  const { data } = await axiosIns.get("/utils/update-current-price");
  return data;
}

export async function updateHistoricalPrices() {
  const { data } = await axiosIns.get("/utils/update-historical-price");
  return data;
}

export async function updateSecuritiesData() {
  const { data } = await axiosIns.get("/utils/update-securities-data");
  return data;
}

export async function updateExchangeRates() {
  const { data } = await axiosIns.get("/utils/update-exchange-rates");
  return data;
}

// Rebalancing
export async function rebalancingItems(id) {
  const params = {
    portfolio_id: id,
  };

  const { data } = await axiosIns.get("/rebalancing/", { params });
  return data;
}

export async function updateRebalancingItems(pid, body) {
  const { data } = await axiosIns.post(`${url}/portfolios/${pid}/rebalancing/`, { body });
  return data;
}

// Analytics
export async function historicalPortfolioCost(body) {
  const { data } = await axiosIns.post(`${url}/portfolios/analytics/portfolio-cost/`, body);
  return data;
}
