import Vue from "vue";
import axios from "axios";

import getEnv from "@/env";

const axiosIns = axios.create({
  baseURL: getEnv("VUE_APP_API_PATH"),
  withCredentials: true,
  // timeout: 1000,
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
