import axiosIns from "@/libs/axios";

const resource = "portfolios";

export default {
  async get() {
    const { data } = await axiosIns.get(`/${resource}/`);
    return data;
  },

  async getPortfolio(id) {
    const { data } = await axiosIns.get(`/${resource}/${id}`);
    return data;
  },

  async create(payload) {
    const { data } = await axiosIns.post(`/${resource}/`, payload);
    return data;
  },

  async update(payload) {
    const { data } = await axiosIns.put(`/${resource}/${payload.id}`, payload);
    return data;
  },

  async delete(pid) {
    const { data } = await axiosIns.delete(`/${resource}/${pid}`);
    return data;
  },
};
