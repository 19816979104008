import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';

// 3rd party plugins
import '@axios';
import '@/libs/acl';
// import '@/libs/portal-vue'
import '@/libs/toastification';
// import '@/libs/sweet-alerts'
import '@/libs/vue-select';
import '@/libs/echart';

// BSV Registration
Vue.use(BootstrapVue);

// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css'); // For form-wizard

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

// VeeValidate
// import './libs/vee-validate';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
