import axiosIns from "@/libs/axios";

const resource = "transactions";

export default {
  async get(params) {
    const { data } = await axiosIns.get(`/${resource}/`, {
      params,
    });
    return { total: data.total, transactions: data.result };
  },

  async getTransaction(id) {
    const { data } = await axiosIns.get(`/${resource}/${id}`);
    return data;
  },

  async create(payload) {
    const { data } = await axiosIns.post(`/${resource}/`, payload);
    return data;
  },

  async update(payload) {
    const { data } = await axiosIns.put(`/${resource}/${payload.id}`, payload);
    return data;
  },

  async delete(id) {
    const { data } = await axiosIns.delete(`/${resource}/${id}`);
    return data;
  },
};
